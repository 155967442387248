<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="align-items-center col d-flex justify-content-end text-right">
          <date-range-picker
            v-model="dateRange"
            :maxDate="new Date()"
            @update="val => $emit('onDateRangeUpdate', val)"
            autoApply
            opens="left"
            ref="picker"
            class="mr-3"
          >
            <template v-slot:input="picker" style="min-width: 350px;">
              {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
            </template>
          </date-range-picker>

          <base-button type="info" size="sm" @click="handleDownloadAllCSV">
            <span v-if="loadingDownloadCSV">Loading...</span>
            <span v-else>Download</span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="orders && orders.data && orders.data.docs">
        <template slot="columns">
          <th>Kundennr</th>
          <th>Company</th>
          <th>Datum</th>
          <th>Zeit</th>
          <th>Farbe</th>
          <th>Druckseite</th>
          <th>Seitenzahl</th>
          <th>Brief</th>
          <th>Brief_matchcode</th>
          <th>Kostenstelle</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.userId.customerNo || '-' }}</span>
              </div>
            </div>
          </th>
          <td>{{ row.userId.company || '-' }}</td>
          <td>{{ row.printedAt | formatDate }}</td>
          <td>{{ row.printedAt | formatTime }}</td>
          <td>{{ row.colormode }}</td>
          <td>
            <span v-if="row.duplex">2-seitig</span>
            <span v-else>1-seitig</span>
          </td>
          <td>{{ calculatePageCount(row) }}</td>
          <td>{{ row.letterType ? row.letterType.product : '' }} <span v-if="row.briefType">{{ row.briefType }}</span></td>
          <td>{{ row.letterType ? row.letterType.matchcode : '' }}</td>
          <td>{{ row.groupLocations && row.groupLocations.length ? row.groupLocations.join(', ') : null }}</td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination v-if="orders && orders.data" :pageCount="orders.data.totalPages" :value="orders.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'dashboard-table',
  props: {
    type: {
      type: String
    },
    status: {
      type: String
    },
    title: String,
    orders: Object
  },
  data() {
    return {
      loadingDownloadCSV: false,
      dateRange: {
        startDate: null,
        endDate: null
      }
    }
  },
  methods: {
    calculatePageCount (row) {
      let count = 0
      if (row.document) {
        count += row.document.pageCount
      }
      if (row.attachments && row.attachments.length) {
        count += row.attachments.map(a => a.pageCount).reduce((a, b) => a + b, 0)
      }
      return count
    },
    msieversion () {
      var ua = window.navigator.userAgent
      var msie = ua.indexOf("MSIE ")
      // eslint-disable-next-line no-useless-escape
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        return true
      }
      return false
    },
    getHeaderCSV () {
      let CSV = ''
      let row = ''
      const headers = ['Kundennr', 'Kunden-UPOC', 'Company', 'Datum', 'Zeit', 'Farbe', 'Druckseite', 'Seitenzahl', 'AuftragsUPOC', 'Brief', 'Brief_matchcode', 'Kostenstelle']
      for (const header of headers) {
        row += header + ';'
      }
      row = row.slice(0, -1)
      CSV += row + '\r\n'
      return CSV
    },
    getCSVFromOrders (orders) {
      let CSV = ''

      const orderData = orders.map(o => ({
        Kundennr: o.userId.customerNo,
        'Kunden-UPOC': o.customerUpoc || null,
        Company: o.userId.company,
        Datum: moment(o.printedAt).format('DD.MM.YYYY'),
        Zeit: moment(o.printedAt).format('HH:mm:ss'),
        Farbe: o.colormode,
        Druckseite: o.duplex ? '2-seitig' : '1-seitig',
        Seitenzahl: this.calculatePageCount(o),
        AuftragsUPOC: o.upocId ? o.upocId.code : null,
        Brief: `${o.letterType ? o.letterType.product + ' ' : ''}${o.briefType ? o.briefType : ''}`,
        Brief_matchcode: o.letterType ? o.letterType.matchcode : null,
        Kostenstelle: (o.groupLocations && o.groupLocations.length) ? o.groupLocations.join(', ') : ''
      }))

      for (let i = 0; i < orderData.length; i++) {
        let row = ''
        for (let index in orderData[i]) {
          let arrValue = orderData[i][index] == null ? "" : '="' + orderData[i][index] + '"'
          row += arrValue + ';'
        }
        row.slice(0, row.length - 1)
        CSV += row + '\r\n'
      }
      return CSV
    },
    handleDownloadAllCSV () {
      this.loadingDownloadCSV = true

      // Retrieve all billed orders with no pagination
      const params = {
        query: {
          status: 'printed'
        },
        includeManaged: true,
        pagination: false
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        params.isPrintedBetween = [
          this.dateRange.startDate,
          this.dateRange.endDate
        ]
      }

      this.$store.dispatch('order/fetchAllOrders', params)
        .then(async ({ data }) => {
          let CSV = this.getHeaderCSV()

          data.docs.forEach((order) => {
            CSV += this.getCSVFromOrders([order])
          })
          this.downloadFileFromCSV(CSV)
        })
        .catch(({error}) => {
          this.$notify({type: 'danger', message: error.http_message})
        })
        .finally(() => {
          this.loadingDownloadCSV = false
        })
    },
    downloadFileFromCSV (CSV) {
      const fileName = `${new Date().getTime().toString()}.csv`
      if(this.msieversion()) {
        const IEwindow = window.open()
        IEwindow.document.write('sep=,\r\n' + CSV)
        IEwindow.document.close()
        IEwindow.document.execCommand('SaveAs', true, fileName)
        IEwindow.close()
      } else {
        const uri = 'data:application/csv;charset=utf-8,' + escape(CSV)
        const link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
}
</script>

<style>
.reportrange-text {
  height: 32px;
}
</style>
